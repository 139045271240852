import { EyeOutlined } from '@ant-design/icons'
import { Col, Image, Input, Row, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { format } from 'date-fns'
import { FC, useEffect, useState } from 'react'

import {
  SentAttachment,
  SentAttachmentDocument,
} from '../../../api/attachment-standalone/attachment-standalone-client'
import {
  GetImageFromS3,
  GetSentAttachmentImage,
} from '../../../api/attachment-standalone/attachmentStandaloneApi'
import { dataURLtoFile, formatDateFromString } from '../../../utilities/general'
import { SentImageDataProps } from '../AttachmentsTypes'
import CopyAttachmentIdButton from '../Common/_copyAttachmentIdButton'

import './SentAttachmentContent.scss'

import { VyneButton, VyneModal } from '@vynedental/design-system'

const { TextArea } = Input

type SentAttachmentContentProps = {
  sentAttachment: SentAttachment
}

const SentAttachmentContent: FC<SentAttachmentContentProps> = ({
  sentAttachment,
}) => {
  const { documents } = sentAttachment
  const [imageData, setImageData] = useState<SentImageDataProps[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [narrativeText, setNarrativeText] = useState<string>(
    sentAttachment?.narrative,
  )
  const [imageModalProps, setImageModalProps] =
    useState<SentImageDataProps>(null)

  const sentImageColumns: ColumnsType<SentImageDataProps> = [
    {
      dataIndex: 'rowId',
      width: '40px',
    },
    {
      dataIndex: 'imageData',
      title: 'Image',
      width: '172px',
      onCell: (record: SentImageDataProps) => ({
        onClick: () => setImageModalProps(record),
      }),
      render: (value: Blob) => (
        <img
          src={URL.createObjectURL(value)}
          width='98px'
          height='64px'
        />
      ),
    },
    {
      dataIndex: 'dateTaken',
      title: 'Date Taken',
      width: '172px',
      render: (value: string) => (
        <span>{value ? format(new Date(value), 'MM/dd/yyy') : ''}</span>
      ),
    },
    {
      dataIndex: 'imageType',
      title: 'Image Type',
      width: '172px',
    },
    {
      dataIndex: 'imageData',
      width: '80px',
      render: (_: string, record: SentImageDataProps) => (
        <VyneButton
          dataTestId='view-image-button'
          icon={<EyeOutlined />}
          onClick={() => setImageModalProps(record)}
          type='text'
        />
      ),
    },
  ]

  useEffect(() => {
    const asyncForEach = async (array: any, callback: any) => {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
      }
    }

    // Making an API call for each document/image tied to the attachment, to retreive the image file path
    ;(async () => {
      const images: SentImageDataProps[] = []

      setLoading(true)
      await asyncForEach(
        documents,
        async (doc: SentAttachmentDocument, index: number) => {
          await GetSentAttachmentImage(
            sentAttachment?.hmfId,
            doc?.imageSequence,
          ).then(async ({ data }) => {
            const imageFileData = await GetImageFromS3(data.documentPath)
            const imageFile = dataURLtoFile(
              'data:image/jpeg;base64,' + imageFileData,
              doc.documentName,
            )

            images.push({
              rowId: index + 1,
              imageData: imageFile,
              dateTaken: doc.dateTaken,
              imageType: doc.documentType,
            })
          })
        },
      )
      setImageData([...images])
      setLoading(false)
    })()
  }, [])

  const SentAttachmentImageModal = imageModalProps && (
    <VyneModal
      dataTestId='sent-attachment-content-preview-modal'
      title='Image Preview'
      onCancel={() => setImageModalProps(null)}
      destroyOnClose
      open={imageModalProps !== null}
      footer={null}
    >
      <Row>
        <Col span={16}>
          <p className='fs-125'>{`${sentAttachment.patientFirstName} ${sentAttachment.patientLastName}`}</p>
          <p>
            {formatDateFromString(
              sentAttachment.patientDateOfBirth,
              'MM-dd-yyyy',
            )}
          </p>
        </Col>
        <Col span={4}>
          <p>
            <b>Date Taken</b>
          </p>
          <p>
            {imageModalProps?.dateTaken &&
              formatDateFromString(imageModalProps.dateTaken, 'MM-dd-yyyy')}
          </p>
        </Col>
        <Col span={4}>
          <p>
            <b>Image Type</b>
          </p>
          <p>{imageModalProps.imageType}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Image
            className='sa-sent-content__modal-image'
            preview={false}
            src={URL.createObjectURL(imageModalProps.imageData)}
          />
        </Col>
      </Row>
    </VyneModal>
  )

  return (
    <main className='sa-sent-content'>
      {imageModalProps && SentAttachmentImageModal}
      <Row>
        <Col className='sa-sent-content--header'>
          <p className='sa-sent-content--header-text'>
            <b>{`NEA#${sentAttachment?.hmfId}`}</b>
          </p>
          <CopyAttachmentIdButton attachmentId={sentAttachment?.hmfId} />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={1}>
          <Table
            size='middle'
            columns={sentImageColumns}
            dataSource={imageData}
            loading={loading}
            rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
            rowKey='rowId'
            pagination={false}
            scroll={imageData.length > 5 && { y: 450 }}
          />
          <section className='sa-sent-content--narrative-header'>
            <p className='fs-100'>
              <b>Narrative</b>
            </p>
            <p>{`${narrativeText?.length || 0}/2000 Characters`}</p>
          </section>
          <TextArea
            title='Narrative'
            value={narrativeText}
            maxLength={2000}
            onChange={(e) => setNarrativeText(e.target.value)}
            rows={6}
            disabled
          />
        </Col>
      </Row>
    </main>
  )
}

export default SentAttachmentContent
