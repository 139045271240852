import { DeleteOutlined } from '@ant-design/icons'
import { Observable } from '@legendapp/state'
import { Checkbox, Form, Popconfirm, Radio, Tooltip } from 'antd'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { FormInput, FormSelect } from 'ui'
import { validateAlphaNumeric, validateNPI, validateTIN } from 'utils'

import { Errors } from 'trellis:constants/errors'
import { TaxonomyCodes } from 'trellis:constants/general'
import { NotifyText } from 'trellis:constants/notifyText'
import { useGlobalContext } from 'trellis:context/GlobalContextProvider'
import api from 'trellis:utilities/api'
import { extractTypeProperties, showMessage } from 'trellis:utilities/general'

import './AddProviderModal.scss'

import { observer } from '@legendapp/state/react'
import { VyneButton, VyneModal, VyneRadio } from '@vynedental/design-system'

import { PracticeInfo } from 'trellis:api/practice/practice-client'

interface ProviderFields {
  isDefault: boolean
  licenseNumber: string
  providerFirstName: string
  providerLastName: string
  providerNPI: string
  serviceType: string
  taxId: string
  taxonomyCode: string
}

export interface Provider extends ProviderFields {
  eligibilityDefaultProviderID: number
  globalCustomerID: number
}

interface AddProviderModalProps {
  hasProviders: boolean
  hasUpdatedProviders: Observable<boolean>
  isEditMode?: boolean | null
  isOpen: Observable<boolean>
  provider?: Provider
  setIsEditMode?: Dispatch<SetStateAction<boolean>> | null
  setProvider?: Dispatch<SetStateAction<Provider>> | null
}

const emptyProvider: Provider = {
  eligibilityDefaultProviderID: 0,
  globalCustomerID: 0,
  isDefault: false,
  licenseNumber: '',
  providerFirstName: '',
  providerLastName: '',
  providerNPI: '',
  serviceType: 'Billing',
  taxId: '',
  taxonomyCode: '',
}

const emptyProviderFields: ProviderFields = {
  isDefault: false,
  licenseNumber: '',
  providerFirstName: '',
  providerLastName: '',
  providerNPI: '',
  serviceType: 'Billing',
  taxId: '',
  taxonomyCode: '',
}

const AddProviderModal: FC<AddProviderModalProps> = observer(
  ({
    hasProviders,
    hasUpdatedProviders,
    isEditMode = null,
    isOpen,
    provider = emptyProvider,
    setIsEditMode = null,
    setProvider = null,
  }) => {
    const { authentication, practiceDetails, updatePracticeDetails } =
      useGlobalContext()

    const [isDefaultProvider, setIsDefaultProvider] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [providerFields, setProviderFields] =
      useState<ProviderFields>(emptyProviderFields)

    useEffect(() => {
      if (setProvider)
        setProvider({
          ...provider,
          isDefault: !hasProviders || provider.isDefault,
        })
    }, [provider.eligibilityDefaultProviderID])

    useEffect(() => {
      if (provider.isDefault) setIsDefaultProvider(true)
      const fieldsFromProvider = extractTypeProperties<
        ProviderFields,
        Provider
      >(providerFields, provider)
      setProviderFields({ ...fieldsFromProvider })
      addProviderForm.setFieldsValue({ ...fieldsFromProvider })
    }, [provider])

    const [addProviderForm] = Form.useForm()

    const handleModalClose = () => {
      if (setProvider) setProvider(emptyProvider)
      setProviderFields(emptyProviderFields)
      setIsDefaultProvider(false)
      if (setIsEditMode) setIsEditMode(false)
      isOpen.set(false)
    }

    const handleDeleteProvider = () => {
      api
        .deleteProvider(provider.eligibilityDefaultProviderID)
        .then(() => {
          hasUpdatedProviders.set(true)
          showMessage(NotifyText.deleteProviderSuccess, 'success')
          handleModalClose()
        })
        .catch(() => showMessage(NotifyText.deleteProviderError, 'error'))
    }

    const onValuesChange = (changedValues: ProviderFields) => {
      setProviderFields({ ...providerFields, ...changedValues })
    }

    const onFinish = async () => {
      setIsSaving(true)

      const providerRequest = { ...provider, ...providerFields }
      if (authentication.CustomerId)
        providerRequest.globalCustomerID = authentication.CustomerId
      if (!hasProviders) providerRequest.isDefault = true

      await api
        .addProvider(providerRequest)
        .then(() => {
          if (providerRequest.isDefault) {
            const newPracticeDetails: PracticeInfo = {
              ...practiceDetails,
              providerFirstName: providerRequest.providerFirstName,
              providerLastName: providerRequest.providerLastName,
              providerNPI: providerRequest.providerNPI,
            }
            updatePracticeDetails({ ...newPracticeDetails })
          }
          hasUpdatedProviders.set(true)
          showMessage(NotifyText.addProviderSuccess, 'success')
          handleModalClose()
        })
        .catch(() => showMessage(NotifyText.addProviderError, 'error'))
        .finally(() => setIsSaving(false))
    }

    return (
      <VyneModal
        afterClose={() => isOpen.set(false)}
        dataTestId='add-provider-modal'
        destroyOnClose={true}
        footer={[
          <section
            className={`add-provider-modal-footer add-provider-modal-footer--${
              isEditMode ? 'has-delete-option' : 'default'
            }`}
            key='add-provider-modal-footer'
          >
            {isEditMode &&
              (isDefaultProvider ? (
                <Tooltip
                  placement='top'
                  title='Add a new provider as default before deleting the existing default provider'
                  trigger='click'
                >
                  <DeleteOutlined />
                </Tooltip>
              ) : (
                <Popconfirm
                  cancelText='No'
                  onCancel={(e) => e.currentTarget.blur()}
                  onConfirm={() => handleDeleteProvider()}
                  okText='Yes'
                  title='Are you sure you want to delete this provider?'
                >
                  <DeleteOutlined />
                </Popconfirm>
              ))}
            <div className='footer-buttons'>
              <VyneButton
                dataTestId='cancel-add-provider-button'
                key='cancel'
                onClick={handleModalClose}
              >
                Cancel
              </VyneButton>
              <VyneButton
                dataTestId='submit-add-provider-button'
                form='addProviderForm'
                htmlType='submit'
                loading={isSaving}
                key='add'
                type='primary'
              >{`${isEditMode ? 'Save' : 'Add'}`}</VyneButton>
            </div>
          </section>,
        ]}
        onCancel={handleModalClose}
        title={`${isEditMode ? 'Edit provider' : 'Add a provider'}`}
        open={isOpen.get()}
        width={575}
      >
        <Form
          colon={false}
          form={addProviderForm}
          name='addProviderForm'
          onFinish={onFinish}
          onFinishFailed={() => showMessage(Errors.formValidationErrors)}
          onValuesChange={(changedValues) => onValuesChange(changedValues)}
          requiredMark={false}
        >
          <section className='page-section'>
            <h2 className='page-section__title page-section__title--modal'>
              Service Type
            </h2>
            <Form.Item name='serviceType'>
              <Radio.Group>
                <VyneRadio
                  dataTestId='billing-provider-radio-button'
                  value='Billing'
                >
                  Billing Provider
                </VyneRadio>
                <VyneRadio
                  dataTestId='treating-provider-radio-button'
                  value='Treatment'
                >
                  Treating Provider
                </VyneRadio>
              </Radio.Group>
            </Form.Item>
          </section>
          <section>
            <h2 className='page-section__title page-section__title--modal'>
              Details
            </h2>
            <div className='form-section-row form-section-row--modal'>
              <FormInput
                label='Last or Billing Name'
                name='providerLastName'
                required
              />
              <FormInput
                label='First Name'
                name='providerFirstName'
              />
            </div>
            <div className='form-section-row form-section-row--modal'>
              <FormInput
                label='Tax ID'
                name='taxId'
                required
                validator={validateTIN}
              />
              <FormInput
                label='License No.'
                name='licenseNumber'
                required
                validator={validateAlphaNumeric}
              />
            </div>
            <div className='form-section-row form-section-row--modal'>
              <FormInput
                label='NPI No.'
                name='providerNPI'
                required
                validator={validateNPI}
              />
              <FormSelect
                label='Taxonomy Code'
                name='taxonomyCode'
                options={TaxonomyCodes}
                required
              />
            </div>
            <Tooltip
              placement='topLeft'
              title={
                isDefaultProvider || !hasProviders
                  ? 'A default provider must be selected'
                  : null
              }
              trigger='hover'
            >
              <Form.Item name='isDefault'>
                <Checkbox
                  checked={
                    providerFields.isDefault || !hasProviders ? true : false
                  }
                  disabled={isDefaultProvider || !hasProviders}
                  onChange={() =>
                    onValuesChange({
                      ...providerFields,
                      isDefault: !providerFields.isDefault,
                    })
                  }
                >
                  Use as default provider on transactions
                </Checkbox>
              </Form.Item>
            </Tooltip>
          </section>
        </Form>
      </VyneModal>
    )
  },
)

export default AddProviderModal
