import { observer, useComputed } from '@legendapp/state/react'
import { VyneButton, VyneModal } from '@vynedental/design-system'
import { FC } from 'react'

import styles from './_downloads.module.scss'
import { Download } from './utils/downloads-helpers'

import DownloadIcon from 'trellis:assets/cloud-download-icon.svg?react'
import SendIcon from 'trellis:assets/send-icon.svg?react'
import { ObservablePrimitive } from '@legendapp/state'

interface DownloadItemContentProps {
  download: Download
  isAdditional?: boolean
}

type dlButtonProps = {
  hasConfirmationModal: ObservablePrimitive<string>
  url: string
  showInfo: () => void
}

const dlButton = ({ hasConfirmationModal, url, showInfo}: dlButtonProps) => {
  return (
    <VyneButton
      dataTestId='download-button'
      href={!hasConfirmationModal.get() ? url : null}
      icon={
        <span
          className={`anticon ${styles['download-item__icons--vertical-align']}`}
        >
          <DownloadIcon />
        </span>
      }
      onClick={hasConfirmationModal.get() ? () => showInfo() : null}
      type='primary'
    >
      Download
    </VyneButton>
  )
}

const { info } = VyneModal

export const DownloadItemContent: FC<DownloadItemContentProps> = observer(
  ({ download, isAdditional = false }) => {
    const {
      confirmationPromptMessage,
      confirmationPromptTitle,
      installGuideUrl,
      logoUrl,
      mailToBody,
      mailToSubject,
      notes,
      systemReqsUrl,
      title,
      url,
      versionNumber,
    } = download

    const hasConfirmationModal = useComputed(
      () => confirmationPromptTitle && confirmationPromptMessage,
    )

    const infoModalContent = (
      <div className='text-center'>
          <h6 className='fs-100 mb-050'>{confirmationPromptTitle}</h6>
          <p className='fs-0875'>{confirmationPromptMessage}</p>
        </div>
    )

    const showInfo = () => {
      const modal = info({
        closable: true,
        content: infoModalContent,
        footer: () => (
          [
            <VyneButton
              dataTestId='cancel-download-button'
              key='cancel'
              onClick={modal.destroy}
            >
              Cancel
            </VyneButton>,
            <VyneButton
              dataTestId='download-button'
              href={url}
              icon={
                <span
                  className={`anticon ${styles['download-item__icons--vertical-align']}`}
                >
                  <DownloadIcon />
                </span>
              }
              key='download'
              onClick={modal.destroy}
              type='primary'
            >
              Download
            </VyneButton>,
          ]
        ),
        onClose: () => modal.destroy(),
        title: `Download ${title}`
      })
    }

    return (
      <>
        <section
          className={`${styles['download-item__card']} ${isAdditional ? styles['download-item__card--additional'] : ''}`}
        >
          <img
            alt={`${title} logo`}
            className={styles['download-item__img']}
            src={logoUrl}
          />
          <div
            className={`flex-column gap-050 ${isAdditional ? 'justify-between' : ''}`}
          >
            <div className='flex-column gap-050'>
              <div className='flex-row items-center gap-050'>
                <h6 className={styles['download-item__title']}>{title}</h6>
                <p className={styles['download-item__version-tag']}>
                  Version: {versionNumber}
                </p>
              </div>
              {notes && (
                <p className={styles['download-item__notes']}>{notes}</p>
              )}
              {confirmationPromptTitle && (
                <p className={styles['download-item__prompt-title']}>
                  <span>NOTE:</span> {confirmationPromptTitle}
                </p>
              )}
            </div>
            <div className={styles['download-item__guide-container']}>
              {systemReqsUrl && (
                <a
                  className={styles['download-item__guide-link']}
                  href={systemReqsUrl}
                  rel='noreferrer'
                  target='_blank'
                >
                  System Requirements
                </a>
              )}
              {installGuideUrl && (
                <>
                  <span></span>
                  <a
                    className={styles['download-item__guide-link']}
                    href={installGuideUrl}
                    rel='noreferrer'
                    target='_blank'
                  >
                    Installation Guide
                  </a>
                </>
              )}
              {isAdditional && (
                <div
                  className={
                    styles['download-item__card--additional-button-container']
                  }
                >
                  {dlButton({
                    hasConfirmationModal,
                    url,
                    showInfo,
                  })}
                </div>
              )}
            </div>
          </div>
          {!isAdditional && (
            <div className='flex-row gap-050'>
              {mailToBody && mailToSubject && (
                <VyneButton
                  dataTestId='share-download-button'
                  href={`mailto:?body=${mailToBody}&subject=${mailToSubject}`}
                  icon={
                    <span
                      className={`anticon ${styles['download-item__icons--vertical-align']}`}
                    >
                      <SendIcon />
                    </span>
                  }
                >
                  Share Download
                </VyneButton>
              )}

              {dlButton({
                hasConfirmationModal,
                url,
                showInfo,
              })}
            </div>
          )}
        </section>
      </>
    )
  },
)
