import { AutoComplete, Table } from 'antd'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { Loading } from '../../_siteWide/loader/dataDisplay'
import { Carrier } from '../../../api/attachment-standalone/attachment-standalone-client'
import { GetRecipientList } from '../../../api/attachment-standalone/attachmentStandaloneApi'
import { GetNeaCarrierRequirements } from '../../../api/carrier/carrierApi'
import { NotifyText } from '../../../constants/notifyText'
import { useFuse } from '../../../utilities/fuzzySearch/useFuse'
import { showMessage } from '../../../utilities/general'
import { FilteredOption } from '../../Eligibility/shared/form/utilities/eligibilityFormTyping'
import { useAttachmentsContext } from '../Context/AttachmentsContext'

import './RequirementsLookupModal.scss'

import { VyneButton, VyneModal } from '@vynedental/design-system'

import CarrierAttachmentSearch from 'trellis:features/carriers/CarrierInfo/CarrierAttachment/_CarrierAttachmentSearch'
import {
  ResultsDisplayType,
  TableDataType,
} from 'trellis:features/carriers/CarrierInfo/CarrierAttachment/_carrierAttachmentTypes'
import {
  getColumns,
  transformRecipientsData,
} from 'trellis:features/carriers/CarrierInfo/CarrierAttachment/_carrierAttachmentUtilities'

interface RequirementsLookupModalProps {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
}

const RequirementsLookupModal: FC<RequirementsLookupModalProps> = ({
  isVisible,
  setIsVisible,
}) => {
  const [tableData, setTableData] = useState<TableDataType[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingReqs, setLoadingReqs] = useState<boolean>(false)
  const [resultsDisplay, setResultsDisplay] =
    useState<ResultsDisplayType>('SearchResults')
  const [searchSelect, setSearchSelect] = useState<string | null>(null)
  const [carriers, setCarriers] = useState<Carrier[]>(null)
  const [carrierOptions, setCarrierOptions] = useState<FilteredOption[]>([])
  const [filteredCarrierOptions, setFilteredCarrierOptions] =
    useState<FilteredOption[]>(carrierOptions)

  const { facilityId } = useAttachmentsContext()

  useEffect(() => {
    ;(async () => {
      await GetRecipientList(facilityId, true)
        .then(({ data }) => {
          setCarriers(data)

          const options: FilteredOption[] = data.map((carrier, index) => {
            return { value: carrier.name, key: index }
          })

          options?.sort((a, b) => (a.value > b.value ? 1 : -1))

          setCarrierOptions(options)
          setFilteredCarrierOptions(options)
        })
        .catch(() => showMessage(NotifyText.getCarriersError, 'error'))
    })()
  }, [])

  const filterListCallBack = (data: TableDataType[]) => {
    let list: TableDataType[] = JSON.parse(JSON.stringify(data))

    if (searchSelect === 'All') {
      return list
    }

    if (searchSelect) {
      const searchRange: [number, number] = searchSelect
        .replaceAll('D', '')
        .split('-')
        .map((value) => parseInt(value)) as [number, number]

      list = list.filter((item) => {
        const dataNumber = parseInt(item.code.replace('D', ''))
        return dataNumber >= searchRange[0] && dataNumber <= searchRange[1]
      })
    }

    return list
  }

  const { hitItems, hits, onSearch } = useFuse<TableDataType>({
    data: tableData,
    filterCallBack: filterListCallBack,
    matchAllOnEmptyQuery: true,
    options: { keys: ['code', 'description', 'requirements'] },
  })

  const updateCarrier = async (value: string) => {
    if (!value) {
      setLoading(true)
      return
    }
    const match = carriers.find(
      (carrier: Carrier) =>
        carrier.name.toLowerCase().trim() === value.toLowerCase().trim(),
    )
    if (match) await getNeaCarrierRequirements(match.neaMasterId)
  }

  const handleOnCarrierSearch = (value: string) => {
    if (value) {
      value = value.toLowerCase()
      const filteredOptions: FilteredOption[] = carrierOptions.filter(
        (option: FilteredOption) =>
          option.value.toLowerCase().includes(value) ||
          option.key.toString().includes(value),
      )

      setFilteredCarrierOptions(filteredOptions)
    } else setFilteredCarrierOptions(carrierOptions)
  }

  const getNeaCarrierRequirements = async (neaMasterId: number) => {
    setLoadingReqs(true)

    await GetNeaCarrierRequirements(neaMasterId)
      .then((res) => {
        const { Recipients } = res.data

        if (
          Recipients.length > 0 &&
          Recipients[0]?.ProcedureCodeRequirements.length > 0
        ) {
          const transformedTableData: TableDataType[] = transformRecipientsData(
            Recipients[0].ProcedureCodeRequirements,
          )
          setTableData(transformedTableData)
          setResultsDisplay('SearchResults')
        } else setResultsDisplay('NoAttachmentService')
      })
      .catch((err) => {
        showMessage('Issue Loading NEA Requirements')
        throw Error(err)
      })
      .finally(() => {
        setLoading(false)
        setLoadingReqs(false)
      })
  }

  return (
    <VyneModal
      dataTestId='standalone-attachments-requirement-lookup-modal'
      destroyOnClose
      footer={[
        <VyneButton
          dataTestId='close-attachment-requirement-lookup-button'
          key='Close'
          onClick={() => setIsVisible(false)}
        >
          Close
        </VyneButton>,
      ]}
      onCancel={() => setIsVisible(false)}
      open={isVisible}
      styles={{
        body: {
          minHeight: '130px',
        },
      }}
      title='Attachment Requirement Lookup'
      width={700}
      wrapClassName='sa-requirements-lookup'
    >
      {carriers ? (
        <main>
          <p className='fs-0875 mb-100'>
            View carrier specific attachment requirements.
          </p>
          <AutoComplete
            className='ant-select-auto-complete--no-float mb-100 pt-000 pb-000 pl-000 pr-000'
            onChange={updateCarrier}
            onSearch={handleOnCarrierSearch}
            options={filteredCarrierOptions}
            placeholder='Select an insurance carrier by name'
            style={{ width: '100%' }}
          />
          <CarrierAttachmentSearch
            data={tableData}
            filterListCallBack={filterListCallBack}
            loading={loading}
            onSearch={onSearch}
            searchSelect={searchSelect}
            setResultsDisplay={setResultsDisplay}
            setSearchSelect={setSearchSelect}
          />
          {loadingReqs ? (
            <Loading />
          ) : resultsDisplay === 'SearchResults' && hitItems.length > 0 ? (
            <Table
              size='small'
              className='mt-050'
              columns={getColumns(hits)}
              dataSource={hitItems}
              loading={loadingReqs}
              pagination={false}
              rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
              rowKey='code'
              scroll={{ y: 200 }}
            />
          ) : (
            !loading && (
              <p className='fs-0875 mt-100'>
                This Carrier has no attachment requirements.
              </p>
            )
          )}
        </main>
      ) : (
        <Loading />
      )}
    </VyneModal>
  )
}

export default RequirementsLookupModal
