import { CheckCircleOutlined } from '@ant-design/icons'
import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { VyneButton, VyneModal } from '@vynedental/design-system'
import { FC } from 'react'

import styles from '../../_downloads.module.scss'

interface ImageSyncSetupCompleteModalProps {
  isOpen: Observable<boolean>
  onClose?: () => void
}

export const ImageSyncSetupCompleteModal: FC<ImageSyncSetupCompleteModalProps> =
  observer(({ isOpen, onClose }) => {
    return (
      <VyneModal
        dataTestId='image-sync-setup-complete-modal'
        title='Provisioning Complete'
        open={isOpen.get()}
        onCancel={onClose}
        footer={[
          <VyneButton
            dataTestId='close-provisioning-complete-button'
            key='close'
            onClick={onClose}
          >
            Close
          </VyneButton>,
        ]}
      >
        <div className='flex-column items-center gap-100'>
          <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a' }} />
          <h6 className={styles['download-item__title']}>
            Pearl Verification Successful
          </h6>
          <p>We successfully verified your Pearl credentials!</p>
        </div>
      </VyneModal>
    )
  })
