import {
  CheckCircleTwoTone,
  InfoCircleFilled,
} from '@ant-design/icons'
import {
  RangePicker,
  RangePickerProps,
  VyneButton,
  VyneModal,
  VyneRadio,
} from '@vynedental/design-system'
import { Form, Popover, Radio, RadioChangeEvent } from 'antd'
import { endOfDay, format, intervalToDuration, startOfDay } from 'date-fns'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'

import { LogError, SelectOption } from 'utils'

import {
  DateRange,
  rangePresets,
  rangeThemeConfig,
} from 'trellis:utilities/datePickerUtilities'

import { FormInput } from '../../_siteWide/form/formInput'
import { Loading } from '../../_siteWide/loader/dataDisplay'
import {
  Attachment,
  Document,
  DocumentType,
  SendAttachment,
} from '../../../api/attachment-standalone/attachment-standalone-client'
import {
  CreateAttachment,
  SendingAttachment,
  UpdateAttachment,
} from '../../../api/attachment-standalone/attachmentStandaloneApi'
import { TrellisProvider } from '../../../api/provider/providerApi'
import { useGlobalContext } from '../../../context/GlobalContextProvider'
import {
  handleBackButtonClick,
  showMessage,
  stringContainsNonAsciiCharacters,
} from '../../../utilities/general'
import ImageSync from '../../attachmentsShared/imageSync/imageSync'
import { AttachmentInformation } from '../../attachmentsShared/shared/shared.interface'
import {
  getImageDataPropsArray,
  handleDocumentType,
} from '../../attachmentsShared/shared/sharedFunctions'
import AttachmentContent from '../AttachmentContent/AttachmentContent'
import AttachmentImage from '../AttachmentImage/AttachmentImage'
import {
  ImageDataProps,
  ImageRules,
  isXrayImageType,
  PatientInfo,
} from '../AttachmentsTypes'
import { useAttachmentsContext } from '../Context/AttachmentsContext'
import PatientInfoModal from '../Patient/PatientInfoModal/PatientInfoModal'
import AttachmentViewHeader from './_AttachmentViewHeader'
import {
  editAttachmentSetup,
  getIdsForNewAttachment,
  handleAttachmentDocumentUpload,
  newAttachmentSetup,
} from './_attachmentViewServices'
import SendProgressModal from './_SendProgressModal'
import FileUpload from './FileUpload'
import KeyboardCapture from './KeyboardCapture'
import ScreenCapture from './ScreenCapture'

import './AttachmentView.scss'

const { success, warning } = VyneModal

const AttachmentView: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [attachmentLoaded, setAttachmentLoaded] = useState<boolean>(false)
  const [attachment, setAttachment] = useState<Attachment>(null)
  const [serviceInfoType, setServiceInfoType] = useState<number>(0)
  const [dateRange, setDateRange] = useState<DateRange>({
    dates: [new Date(), new Date()],
    numberOfDays: 0,
  })
  const [carrier, setCarrier] = useState<{
    carrierReferenceNumber: string
    carrierName: string
  }>({
    carrierReferenceNumber: null,
    carrierName: '',
  })
  const [imageTypes, setImageTypes] = useState<DocumentType[]>([])
  const [imageRules, setImageRules] = useState<ImageRules>(null)
  const [narrativeText, setNarrativeText] = useState<string>(null)
  const [sendingAttachment, setSendingAttachment] = useState<boolean>(false)
  const [sentResponse, setSentResponse] = useState<SendAttachment>(null)
  const [displayEditImage, setDisplayEditImage] = useState<boolean>(false)
  const [imageDataProps, setImageDataProps] = useState<ImageDataProps>(null)
  const [imageData, setImageData] = useState<ImageDataProps[]>([])
  const [editPatient, setEditPatient] = useState<boolean>(false)
  const [providers, setProviders] = useState<TrellisProvider[]>([])
  const [providerOptions, setProviderOptions] = useState<SelectOption[]>([])
  const [defaultProvider, setDefaultProvider] = useState<TrellisProvider>(null)

  const {
    authentication,
    facilityId,
    vdsPatientResponse,
    setVdsPatientResponse,
    patientInfo,
    setPatientInfo,
    attachmentId,
    setAttachmentId,
    setSentAttachments,
    carriers,
  } = useAttachmentsContext()

  const { trellisProviderDetails, authentication: trellisAuthentication } =
    useGlobalContext()

  const backButtonHandler = () => {
    setPatientInfo(null)
    setAttachmentId(null)
  }

  useEffect(() => {
    handleBackButtonClick(backButtonHandler)
  }, [])

  const [lastSavedState, setLastSavedState] = useState<PatientInfo>()

  useEffect(() => {
    if (attachmentId === -1) {
      // New Attachment
      ;(async () => {
        if (vdsPatientResponse) {
          // Patient Search
          await getIdsForNewAttachment(
            authentication,
            trellisAuthentication,
            facilityId,
            vdsPatientResponse,
            trellisProviderDetails,
            setVdsPatientResponse,
            setEditPatient,
            setAttachment,
            setAttachmentLoaded,
            setPatientInfo,
            setImageTypes,
            setImageRules,
            carriers,
          )
        } else {
          // Add Patient
          await newAttachmentSetup(
            authentication,
            trellisProviderDetails,
            setPatientInfo,
            setEditPatient,
          )
        }
      })()
    } else {
      // Edit existing attachment
      ;(async () => {
        await editAttachmentSetup(
          authentication,
          setLoading,
          attachmentId,
          carrier,
          setCarrier,
          setAttachment,
          setServiceInfoType,
          setNarrativeText,
          setDateRange,
          setImageTypes,
          setImageRules,
          setAttachmentLoaded,
          patientInfo,
          setPatientInfo,
          trellisProviderDetails,
          setEditPatient,
          carriers,
        )
      })()
    }
  }, [])

  useEffect(() => {
    if (trellisProviderDetails) {
      setProviders(trellisProviderDetails.treatingProviders)
      setDefaultProvider(trellisProviderDetails.defaultTreatingProvider)

      const options: SelectOption[] =
        trellisProviderDetails.treatingProviders.map(
          (provider: TrellisProvider) => {
            return {
              text: `${provider.providerFirstName} ${provider.providerLastName}`,
              value: provider.eligibilityDefaultProviderID,
            }
          },
        )

      setProviderOptions(options)
    }
  }, [trellisProviderDetails])

  const prepareAttachmentForSave = async (isHold: boolean) => {
    setLoading(true)
    const attachmentCopy = { ...attachment }
    try {
      if (serviceInfoType === 2) {
        // Prior authorization
        attachmentCopy.priorAuthorization = true
        attachmentCopy.serviceDateFrom = null
        attachmentCopy.serviceDateTo = null
      } else {
        // Claim
        attachmentCopy.priorAuthorization = false
        if (dateRange) {
          const fromDate = format(dateRange.dates[0], 'yyy-MM-dd')
          const toDate = format(dateRange.dates[1], 'yyy-MM-dd')
          attachmentCopy.serviceDateFrom = fromDate
          attachmentCopy.serviceDateTo = toDate
        }
      }
      attachmentCopy.recipientReferenceNumber = carrier.carrierReferenceNumber
      attachmentCopy.narrative = narrativeText
      attachmentCopy.statusId = isHold ? 3 : 2

      // handle documents
      attachmentCopy.documents = []

      if (imageData) {
        for (const documentProps of imageData) {
          const documentCopy: Document = documentProps.document
            ? { ...documentProps.document }
            : ({} as Document)
          documentCopy.overwriteDocument = documentProps.imageChanged
          documentCopy.patientId = attachmentCopy.patientId
          documentCopy.documentCode = documentProps.imageType?.documentCode
          documentCopy.orientationTypeId =
            documentProps.orientation && documentProps.orientation === 'Right'
              ? 2
              : 1
          documentCopy.orientationMethodId =
            documentProps.imageType?.documentTypeId &&
            documentProps.imageType?.documentTypeId === 3
              ? 3
              : 2
          documentCopy.createDate = format(
            new Date(documentProps.dateTaken),
            "yyyy-MM-dd'T'HH:mm:ss",
          )
          documentCopy.imageStatusChangeDate = format(
            new Date(),
            "yyyy-MM-dd'T'HH:mm:ss",
          )
          documentCopy.acquisitionMethod = documentProps.acquisitionType

          if (documentCopy.overwriteDocument) {
            const documentFilename = await handleAttachmentDocumentUpload(
              authentication,
              facilityId,
              documentCopy,
              documentProps.imageData,
            )
            documentCopy.documentFileName = documentFilename
          }

          attachmentCopy.documents.push(documentCopy)
        }
      }
    } catch {
      showMessage(
        'Failed to update attachment. Please try saving or sending again.',
      )
      return null
    } finally {
      setLoading(false)
    }

    return attachmentCopy
  }

  const validateAttachmentSave = (attachmentCopy: Attachment) => {
    let isValid = true

    attachmentCopy.documents.forEach((doc) => {
      const imageType = imageTypes.find((item) =>
        item.documentCode === doc.documentCode ? item : null,
      )
      if (!imageType) {
        showMessage('Image Type is required for each image.')
        isValid = false
        return
      }

      if (
        !doc.createDate &&
        isXrayImageType(imageType?.documentTypeGroupDescription)
      ) {
        showMessage('Date Taken is required for each x-ray image type.')
        isValid = false
        return
      }
    })

    if (!attachmentCopy.narrative && attachmentCopy.documents?.length === 0) {
      showMessage(
        'Attachment must include at least one narrative or image to be sent.',
      )
      isValid = false
    }
    if (
      attachmentCopy.narrative &&
      stringContainsNonAsciiCharacters(attachmentCopy.narrative)
    ) {
      showMessage('Narrative contains invalid (non-Ascii) characters.')
      isValid = false
    }
    if (serviceInfoType !== 1 && serviceInfoType !== 2) {
      showMessage(
        'Please select either Claim or Prior Authorization as the service type.',
      )
      isValid = false
    }
    if (
      !attachmentCopy.priorAuthorization &&
      !(attachmentCopy.serviceDateFrom || attachmentCopy.serviceDateTo)
    ) {
      showMessage(
        'Date of Service must be provided if Prior Authorization is not selected.',
      )
      isValid = false
    }

    return isValid
  }

  const validateAttachmentMandatory = (attachmentCopy: Attachment) => {
    let isValid = true

    attachmentCopy.documents.forEach((doc) => {
      const imageType = imageTypes.find((item) =>
        item.documentCode === doc.documentCode ? item : null,
      )
      if (!imageType) {
        showMessage('Image Type is required for each image.')
        isValid = false
      }
    })

    return isValid
  }

  const failedSavingError =
    'Something went wrong while saving the attachment. Please try again.'

  const handleSaveAndClose = async (isHold: boolean) => {
    const attachmentToUpdate = await prepareAttachmentForSave(isHold)
    const isValid = isHold
      ? validateAttachmentMandatory(attachmentToUpdate)
      : validateAttachmentSave(attachmentToUpdate)
    if (attachmentToUpdate && isValid) {
      setLoading(true)
      if (
        !attachmentToUpdate.attachmentId ||
        attachmentToUpdate.attachmentId === -1
      ) {
        await CreateAttachment(isHold, attachmentToUpdate)
          .then(({ data }) => {
            showMessage(
              'Attachment was successfully ' +
                (isHold ? 'updated to Hold.' : 'saved.'),
              'success',
            )
            setAttachment({ ...data })
            setPatientInfo(null)
            setAttachmentId(null)
          })
          .catch((e) => {
            LogError(e, 'Failed to save attachment')
            showMessage(failedSavingError)
            throw new Error(failedSavingError)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        await UpdateAttachment(
          attachmentToUpdate.attachmentId,
          isHold,
          attachmentToUpdate,
        )
          .then(({ data }) => {
            showMessage(
              'Attachment was successfully ' +
                (isHold ? 'updated to Hold.' : 'saved.'),
              'success',
            )
            setAttachment({ ...data })
            setPatientInfo(null)
            setAttachmentId(null)
          })
          .catch((e) => {
            LogError(e, 'Failed to save attachment')
            showMessage(failedSavingError)
            throw new Error(failedSavingError)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }

  const failedSendingError =
    'Something went wrong while sending the attachment. Please try again.'

  const handleSendAttachment = async () => {
    const attachmentToUpdate = await prepareAttachmentForSave(false)
    const isValid = validateAttachmentSave(attachmentToUpdate)
    if (attachmentToUpdate && isValid) {
      setLoading(true)
      setSendingAttachment(true)
      if (
        !attachmentToUpdate.attachmentId ||
        attachmentToUpdate.attachmentId === -1
      ) {
        await CreateAttachment(false, attachmentToUpdate).then(
          async ({ data: savedAttachment }) => {
            await SendingAttachment(savedAttachment.attachmentId)
              .then(({ data: sentResponse }) => {
                setSentResponse(sentResponse)
              })
              .catch((e) => {
                LogError(e, 'Failed to send attachment')
                showMessage(failedSendingError)
                throw new Error(failedSavingError)
              })
              .finally(() => {
                setLoading(false)
              })
          },
        )
      } else {
        await UpdateAttachment(
          attachmentToUpdate.attachmentId,
          false,
          attachmentToUpdate,
        ).then(async ({ data: savedAttachment }) => {
          await SendingAttachment(savedAttachment.attachmentId)
            .then(({ data: sentResponse }) => {
              setSentResponse(sentResponse)
            })
            .catch((e) => {
              LogError(e, 'Failed to send attachment')
              showMessage(failedSendingError)
              throw new Error(failedSendingError)
            })
            .finally(() => {
              setLoading(false)
            })
        })
      }
    }
  }

  const showSendConfirm = () => {
    const modal = success({
      title: 'Send Attachment',
      icon: <CheckCircleTwoTone twoToneColor={'#91C73D'} />,
      closable: true,
      content: 'Would you like to send the attachment now?',
      footer: () => (
        <div>
          <VyneButton
            dataTestId='cancel-send-standalone-attachment-button'
            key='cancel'
            onClick={() => {
              modal.destroy()
            }}
          >
            Cancel
          </VyneButton>
          <VyneButton
            dataTestId='confirm-send-standalone-attachment-button'
            key='submit'
            type='primary'
            onClick={() => {
              handleSendAttachment()
              modal.destroy()
            }}
          >
            Send Now
          </VyneButton>
        </div>
      ),
    })
  }

  const showCancelConfirm = () => {
    const modal = warning({
      title: 'Unsaved Changes',
      closable: true,
      content: 'Would you like to save your changes?',
      footer: () => (
        <div>
          <VyneButton
            dataTestId='cancel-exit-standalone-attachment-view-button'
            key='cancel'
            onClick={() => {
              setPatientInfo(null)
              setAttachmentId(null)
              modal.destroy()
            }}
          >
            Exit without Saving
          </VyneButton>
          <VyneButton
            dataTestId='confirm-exit-standalone-attachment-view-button'
            key='submit'
            type='primary'
            onClick={() => {
              handleSaveAndClose(false)
              modal.destroy()
            }}
          >
            Save and Exit
          </VyneButton>
        </div>
      ),
    })
  }

  const handleOpenPatientModal = () => {
    setLastSavedState(patientInfo)
    setEditPatient(true)
  }

  const handleServiceInfoTypeChange = (serviceInfoType: number) => {
    if (serviceInfoType === 1 && !dateRange) {
      setDateRange({ dates: [new Date(), new Date()], numberOfDays: 0 })
    }
    setServiceInfoType(serviceInfoType)
  }

  const getAttachmentInformation = (
    patient: PatientInfo,
    attachment: Attachment,
  ): AttachmentInformation<DocumentType> => {
    const attachmentInfo = {
      patientFirstName: patient?.patientFirstName,
      patientLastName: patient?.patientLastName,
      patientDateOfBirth: patient?.patientDateOfBirth,
      dateOfServiceFrom:
        serviceInfoType === 1 ? format(dateRange.dates[0], 'yyy-MM-dd') : null,
      dateOfServiceTo:
        serviceInfoType === 1 ? format(dateRange.dates[1], 'yyy-MM-dd') : null,
      imageTypes: imageTypes,
      handleImageType: handleDocumentType,
    }
    return attachmentInfo
  }

  const updateAttachmentImages = (attachmentImageUpdate: ImageDataProps[]) => {
    let rowId = imageData.length
    const imageSyncDataProps = [] as ImageDataProps[]

    // set rowId and handle image type
    for (const imageDataProps of attachmentImageUpdate) {
      imageDataProps.rowId = ++rowId
      imageSyncDataProps.push(imageDataProps)
    }

    setImageData([...imageData, ...imageSyncDataProps])
  }

  const carrierRefPopoverContent = (
    <>
      <p>The Carrier Reference Number may be identified</p>
      <p>on an EOB, ERA, or letter as: Claim Number, DCN,</p>
      <p>Document Control Number, Claim ID, File</p>
      <p>Reference Number, or File Control Number.</p>
    </>
  )

  const setDateFilter = (date: DateRange['dates']) => {
    const durationAsDays: number = intervalToDuration({
      start: date[0],
      end: date[1],
    }).days
    setDateRange({
      dates: [startOfDay(date[0]), endOfDay(date[1])],
      numberOfDays: durationAsDays,
    })
  }

  const handleDateRangeSelect = (dates: DateRange['dates']) =>
    setDateFilter(dates)

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      (current && current > dayjs().endOf('day')) ||
      current < dayjs(patientInfo.patientDateOfBirth)
    )
  }

  return (
    <>
      {sendingAttachment && (
        <SendProgressModal
          loading={loading}
          setLoading={setLoading}
          sendingAttachment={sendingAttachment}
          setSendingAttachment={setSendingAttachment}
          sentResponse={sentResponse}
          setDateRange={setDateRange}
          setSentAttachments={setSentAttachments}
          setAttachmentId={setAttachmentId}
        />
      )}
      {displayEditImage && (
        <AttachmentImage
          imageTypes={imageTypes}
          imageDataProps={imageDataProps}
          setImageDataProps={setImageDataProps}
          imageData={imageData}
          setImageData={setImageData}
          displayEditImage={displayEditImage}
          setDisplayEditImage={setDisplayEditImage}
        />
      )}
      <PatientInfoModal
        isOpen={editPatient}
        setIsOpen={setEditPatient}
        attachment={attachment}
        setAttachment={setAttachment}
        setAttachmentLoaded={setAttachmentLoaded}
        providers={providers}
        providerOptions={providerOptions}
        defaultProvider={defaultProvider}
        setAttachmentId={setAttachmentId}
        setImageTypes={setImageTypes}
        setImageRules={setImageRules}
        lastSavedState={lastSavedState}
      />
      <div className='sa-edit__header'>
        <AttachmentViewHeader
          loading={loading}
          patientInfo={patientInfo}
          carrier={carrier}
          handleOpenPatientModal={handleOpenPatientModal}
          handleSendConfirm={showSendConfirm}
          handleCancelConfirm={showCancelConfirm}
          handleSaveAndClose={handleSaveAndClose}
        />
      </div>
      <div className='mt-100 sa-edit__service'>
        <div className='sa-edit__service-article'>
          <p className='fs-100'>
            <b>Service Information</b>
          </p>
          <section className='sa-edit__service-article-info'>
            <Radio.Group
              className='sa-edit__service-article-info--types'
              disabled={loading}
              value={serviceInfoType}
              onChange={(e: RadioChangeEvent) =>
                handleServiceInfoTypeChange(e.target.value)
              }
            >
              <VyneRadio
                dataTestId='attachments-claim-radio-button'
                value={1}
              >
                Claim
              </VyneRadio>
              <VyneRadio
                dataTestId='attachments-prior-authorization-radio-button'
                disabled={imageRules?.claimPriorAuthorizationDisabled}
                value={2}
              >
                Prior Authorization
              </VyneRadio>
            </Radio.Group>
            <div className='sa-edit__service-article-info--dates'>
              <p className='fs-0875 pr-050'>
                <b>Date of Service</b>
              </p>
              {patientInfo && (
                <RangePicker
                  allowClear={false}
                  disabled={loading || serviceInfoType !== 1}
                  disabledDate={disabledDate}
                  name='attachment-view-range-picker'
                  onChange={(dates, _dateStrings) =>
                    dates[0] &&
                    handleDateRangeSelect([
                      dates[0].toDate(),
                      dates[1].toDate(),
                    ])
                  }
                  presets={rangePresets}
                  theme={rangeThemeConfig}
                  value={
                    dateRange?.dates && [
                      dayjs(dateRange.dates[0]),
                      dayjs(dateRange.dates[1]),
                    ]
                  }
                />
              )}
            </div>
          </section>
          <section className='sa-edit__service-article-acquisition'>
            <ScreenCapture
              setImageDataProps={setImageDataProps}
              imageData={imageData}
              setImageData={setImageData}
            />
            <FileUpload
              setImageDataProps={setImageDataProps}
              imageData={imageData}
              setImageData={setImageData}
            />
            <KeyboardCapture
              setImageDataProps={setImageDataProps}
              imageData={imageData}
              setImageData={setImageData}
            />
            <ImageSync
              attachmentInformation={getAttachmentInformation(
                patientInfo,
                attachment,
              )}
              updateAttachmentImages={updateAttachmentImages}
              handleAttachmentImageAssembly={getImageDataPropsArray}
            />
          </section>
          <section className='sa-edit__service-article-content'>
            {attachmentLoaded ? (
              <AttachmentContent
                attachment={attachment}
                imageTypes={imageTypes}
                imageRules={imageRules}
                narrativeText={narrativeText}
                setNarrativeText={setNarrativeText}
                setImageDataProps={setImageDataProps}
                setDisplayEditImage={setDisplayEditImage}
                imageData={imageData}
                setImageData={setImageData}
                submitting={loading}
              />
            ) : (
              <Loading size='default' />
            )}
          </section>
        </div>
        <div className='mt-275 sa-edit__service-aside'>
          <p className='fs-100'>
            <b>Carrier Reference</b>
          </p>
          <section className='sa-edit__service-aside-carrier-ref'>
            <p className='fs-0875 sa-edit__service-aside-carrier-ref--label'>
              Responding to a carrier request?
              <Popover content={carrierRefPopoverContent}>
                <InfoCircleFilled className='sa-edit__service-aside-carrier-ref--icon' />
              </Popover>
            </p>
            <Form>
              <FormInput
                required={imageRules?.recipientReferenceNumberRequired}
                disabled={
                  loading || imageRules?.recipientReferenceNumberDisabled
                }
                name='carrierReferenceNumber'
                label='Carrier reference # / DCN'
                initialValue={carrier.carrierReferenceNumber}
                element={carrier}
                setElement={setCarrier}
              />
            </Form>
          </section>
          <section className='mt-275 sa-edit__service-aside-lookup'>
            <p className='fs-100'>
              <b>Attachment Requirement Lookup</b>
            </p>
            <section className='sa-edit__service-aside-lookup-content'>
              <p className='fs-0875'>Review attachment requirement details.</p>
              <VyneButton
                dataTestId='view-carrier-list-button'
                disabled={loading}
                href='/CarrierList'
                target='_blank'
              >
                View Carrier List
              </VyneButton>
            </section>
          </section>
        </div>
      </div>
    </>
  )
}

export default AttachmentView
